<template>
  <v-app>
    <div class="container">
      <h1>Terms of Use</h1>
      <br>
      <br>
      <v-card
        class="mx-auto"
        max-width="900"
      >
        <v-card-text>
          <div class="TermsOfUseFullText">
            <br>
            <br>

            <p><span style="color: rgb(89, 89, 89);"><span style="font-size: 1.4em;">
              We are thankful to have you with the heedi community.  This Terms of Use notice is to make it clear to you that by interacting with this website, or utilizing heedi's service, you are automatically agreeing to be contracturally bound to the these Terms of Use in their entirety without modification.  
            </span></span></p>

            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              Any use of the term "user" also includes an entity's representatives, agents, contractors, affiliates, employees, and so forth.
            </span></span></p>


            <h2>Prohibited Uses</h2>
            <ul>
              <li>
                leasing, selling, copying, sublicensing, transferring, or assigning any information, intellectual property, goods, or services provided on the site
              </li>
              <li>
                using the site any illegal purpose
              </li>
              <li>
                gaining unauthorized access to the company's data or the data of other users
              </li>
              <li>
                altering, modifying, adapting, reverse engineering, decompiling, disassembling, or hacking heedi's intellectual property
              </li>
              <li>
                using or exporting the heedi's information, products, or services in violation of U.S. export laws and regulations
              </li>
              <li>
                violating anyone else's legal rights (for example, privacy rights) or any laws (for example, copyright laws) in the user's jurisdiction
              </li>
              <li>
                using the website or the company's services to transmit content that could be deemed unlawful, threatening, harassing, racist, abusive, libelous, pornographic, vulgar, defamatory, obscene, indecent, or otherwise inappropriate, including any messages constituting or encouraging criminal conduct
              </li>
              <li>
                breaching, or attempting to breach, the website's security systems
              </li>
              <li>
                enabling third parties to violate the Terms of Use, and
              </li>
              <li>
                failing to ensure that all end users of the site are at least 18 years of age or older.
              </li>
            </ul>
            <br>
            <br>
            <h2>Service Interruptions and Updates</h2>
            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              <strong><em>heedi is a beta service</em></strong>, which means that from time to time, this website will be unavailable due to scheduled interruptions (for maintenance and upgrade purposes) or unintended shutdowns (for whatever reasons).  Your data, may become unavailabe, or be completely deleted.  Due to the beta level of service, and our active development, you should not expect notification each time there is a disruption in service.  If you have any questions or concerns, please contact <em>support@heedi.com.</em>
            </span></span></p>
            <br>
            <br>
            <h2>Term, Termination, and Survival</h2>
            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              This Agreement shall be effective as of the date (the "Effective Date") the User accepts the terms herein or first accesses, downloads or uses any of the services or information (collectively, the "Services") on the site and shall remain in effect for so long as the User uses or accesses any of the Services (the "Term"). Upon termination of the Term, the User shall no longer be permitted to use or access the Services. The terms herein that contemplate obligations after the Term, including but not limited to Indemnification and Limitation of Liability, Disclaimer, Controlling Law and Severability, and Confidentiality, shall survive termination.
            </span></span></p>
            <br>
            <br>

            <h2>Subscriber Data and Legal Compliance</h2>
            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              All information you, the "user," submit or transmit must fully comply with the law.  heedi will not be held liable whatsoever for your misuse of its data.  heedi has the right to revoke or restrict access to its website, products and services in the event you, the "User," violate these Terms of Use or any applicable law.  When you submit, or transmit, data you are making representations indicating you are the exclusive owner of the data, and that heedi will not be at risk of infringing any intellectual property rights of third parties.
            </span></span></p>

            <br>
            <br>

            <h2>Confidential Information</h2>
            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              All information provided on heedi must be treated with strict confidentiality.
            </span></span></p>

            <br>
            <br>

            <h2>Indemnification and Limitation of Liability</h2>
            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              In the event you, the "User," violate any of these Terms of Use or applicable laws, then you are responsible for indemnifying heedi for any losses or damages that heedi incurs as a result.  Furthermore, heedi will not be held responsible for more than $1 in connection with any reasonable claims.
            </span></span></p>


            <br>
            <br>

            <h2>DMCA Notices</h2>
            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              If you believe tht your copyrighted work has been copied in a manner that constitutes copyright infringement and this infringing work is accessible via the Service, please notify heedi's copyright agent, as set forth in the Digital Millennium Copyright Act of 1999  ("DMCA").  For your complaint to be considered valid under the DMCA law, you must provide the following information in writing at once:
            </span></span></p> 
            <br>
            <ul>
              <li>
                1.  A physical or electronic signature of a person authorized to act on behalf of the copyright owner;<br>
                2.  Identification of the material that is claimed to be infringing and where it is located on the Service;<br>
                3.  Identification of the copyrighted work that you claim has been infringed.  This can be a link to the work;<br>
                4.  Provide your company affiliation (if applicable), mailing address, telephone number, and e-mail address;<br>
                5.  A statement that you have a good faith belief that use of the material, as described in the notice, is not authorized by the copyright owner, its agent, or law, and<br>
                6.  A statement that the information in this Notice is accurate and, under penalty of perjury, that you are the owner, or authorized to act on behalf of the owner.<br>
              </li>
            </ul>
            <br>

            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              Deliver this Notice, with all the items completed, to heedi's Designated Copyright Agent:
              <br>
              <br>
              Copyright Agent<br>
              Anderson Internet LLC<br>
              2634 West Rice Street<br>
              Chicago, Illinois  60622<br>
              dmca@heedi.com<br>
            </span></span></p>


            <br>
            <br>

            <h2>DISCLAIMER</h2>
            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              <strong>Not Healthcare Advice</strong>
            </span></span></p>

            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              This Site is not intended to provide medical advice.  Products, services, information and other content provided on this Site, including information that may be provided on this Site directly or by linking to third-party websites are provided for informational purposes only.  Please consult with a physician or other healthcare professional regarding any medical or health related diagnosis or treatment options
            </span></span></p>

            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              Information provided on this Site and linked websites, including information relating to medical and health conditions, treatments and products may be provided in summary form.  Information on this Site including any product label or packaging should not be considered as a substitute for advice from a healthcare professional.  This Site does not recommend self-management of health issues.  Information on this Site is not comprehensive and does not cover all diseases, ailments, physical conditions, routines or their treatment.  Comments made by Site users are strictly their own personal views made in their own personal capacity anre are not claims made by us or do they represent the position or view of heedi. Product ratings by any current or previous employee or Site users are strictly their own personal views made in their own personal capacity and are not intended as a substitute for appropriate medical care or advice from a healthcare professional.   Contact your healhcare professional promptly should you have any health related questions.  Never disregard or delay medical advice based upon information you may have read on this Site.
            </span></span></p>

            <p><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
              heedi is not responsible for any damages for information or services provided even if heedi has been advised or the possibility of damages
            </span></span></p>









            


            
            
          </div>

        </v-card-text>
      </v-card>
      <br>
      <br>
      <br>

      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <VueFooter :dark-text="true" />
    </div>
  </v-app>
</template>

<script>

  import '@/plugins/vuetify'
  import signinModule from '@/store/signin'
  import userModule from '@/store/user'
  import searchModule from '@/store/search'
  import settingsModule from '@/store/settings'
  import VueFooter from '@/components/VueFooter'

  import { mapActions } from 'vuex'


  let id = 1;

  export default {
    name: "terms",
    order: 16,
    components: {


      VueFooter,



    },
    data () {
      return {
        overlay: false,
        showFooterBool: false,
        heroImageSrc: "",
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8'],


        



        list: [
          {
            name: "task 1",
            tasks: [
              {
                name: "task 2",
                tasks: []
              }
            ]
          },
          {
            name: "task 3",
            tasks: [
              {
                name: "task 4",
                tasks: []
              }
            ]
          },
          {
            name: "task 5",
            tasks: []
          }
        ],
      

        showBlock2: false,
        showBlock3: false,
        showBlock4: false,

        //vuedraggable variables
        enabled: true,
        dragging: false,
        /*
        list: [
          { name: "John", id: 0 },
          { name: "Joao", id: 1 },
          { name: "Jean", id: 2 }
        ],
        */
      }
    },
    computed: {
      draggingInfo() {
        return this.dragging ? "under drag" : "";
      },
      elements: {
          get() {
            return this.$store.state.nested.elements;
          },
          set(value) {
            this.$store.commit("nested/updateElements", value, {root: true});
          }
        },
      
    },
    methods: {
      ...mapActions('signin', [
        'autoSignIn'
      ]),
      ...mapActions('image', [
        'getHeroImage'
      ]),
      ...mapActions('search', [
        'setShowSearch',
        'setShowSearchIcon'
      ]),
      showFooter () {
        this.showFooterBool = true;
      },
      add: function() {
      this.list.push({ name: "Juan " + id, id: id++ });
      },
      replace: function() {
        this.list = [{ name: "Edgard", id: id++ }];
      },
      checkMove: function(e) {
        window.console.log("Future index: " + e.draggedContext.futureIndex);
      }
    },
    watch: {

    },
    async beforeCreate () {
      //MUST HAVE ALL THREE OF THESE MODULES LOADED FOR AUTOSIGNIN TO WORK
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.search) this.$store.registerModule('search', searchModule)
      //if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      //if(!this.$store.state.nested) this.$store.registerModule('nested', nestedModule)
  
    },
    created () {
      // gtag('config', 'G-H9RD8W4MLR', {'page_path': '/about'});
      //this.autoSignIn()
      this.setShowSearchIcon(true)
    },
    mounted () {
    },
    updated () {

    }
  }
</script>

<style scoped>
  .about {
    min-height: 400px;
  }

  .blockWrapper {
    background-color: white;
    padding: 30px;
    
    margin: 0 auto;
  }



  .searchContainer {
    text-align: center;

  }

  ul {
        list-style-type: square;
  }
  ul > li > ul {
    list-style-type: circle;
  }
  ul > li > ul > li > ul {
    list-style-type: square;
  }
  ol li {
    font-family: Arial ;
  }
  


  .moreLink {
    display: block;
    width: 30%;
    max-width: 200px;
    border: 2px solid #999;
    padding: 3px;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    color: #555;
    border-radius: 10px;
    background-color: #ddd;
    
  }
  .moreLink:hover {
    background-color: #000;
    color: white;
  }

  .downIcon {
    color: #777;
  }


  .buttons {
    margin-top: 35px;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .aboutContent {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3em;
    font-weight: 400;
  }

  .searchContainer {
    text-align: center;
    font-size: .7em;

  }

  #heediSupportImg {
    width: 90%;
  }
  .flexContainer {
    display: flex;
    flex-direction: column;
  }
  .heediImgHolder {
    padding: 25px;
    text-align: center;
  }

  [data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
  }

  [data-custom-class='title'], [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
  }
  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
  }
  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
  }
  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }
  [data-custom-class='body_text'], [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }
  [data-custom-class='link'], [data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }

  @media screen and (min-width: 400px) {

    #heediSupportImg {
      width: 240px;
    }

  }

  @media screen and (min-width: 510px) {


    .flexContainer {
      flex-direction: row;
      text-align: left;
    }
  }


</style>